<script>
export default {
  name: "E404",

  methods: {
    toMain() {
      if (this.$store.getters["authentication/hasAccessToken"]) {
        this.$router.push("/");
      } else {
        this.$router.push("/auth/login");
      }
    }
  }
};
</script>

<template>
  <div
    class="error-container text-center d-flex justify-center fill-height align-center"
  >
    <div>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="https://www.w3.org/2000/svg"
        viewBox="0 0 210 115.01"
      >
        <title>404</title>
        <path
          id="_4"
          data-name="4"
          class="cls-1"
          d="M60.83,170.1V149.8H21.49a6.84,6.84,0,0,1-5.05-2,7,7,0,0,1-2-5.1,6.4,6.4,0,0,1,1.39-4.1l46.38-67a6.94,6.94,0,0,1,5.85-3,5.86,5.86,0,0,1,2.68.6A9,9,0,0,1,73,70.7a5.42,5.42,0,0,1,1.49,2.2,6.69,6.69,0,0,1,.5,2.8v59.9h8.32a7.17,7.17,0,0,1,5.05,2,6.67,6.67,0,0,1,2.08,5.1,6.82,6.82,0,0,1-2.08,5.1,7.17,7.17,0,0,1-5.05,2H75v20.3a7.14,7.14,0,0,1-7.14,7.1,6.63,6.63,0,0,1-5-2.1A6.91,6.91,0,0,1,60.83,170.1Zm0-34.5V98.4L35.06,135.7l25.77-.1h0Z"
          transform="translate(-14.45 -62.47)"
        />
        <path
          id="_4-2"
          data-name="4"
          class="cls-1"
          d="M194.82,170.1V149.8H155.47a6.84,6.84,0,0,1-5.05-2,7,7,0,0,1-2-5.1,6.4,6.4,0,0,1,1.39-4.1l46.38-67a7.59,7.59,0,0,1,2.48-2.2,6.47,6.47,0,0,1,3.37-.8,5.86,5.86,0,0,1,2.68.6A9,9,0,0,1,207,70.7a5.42,5.42,0,0,1,1.49,2.2,6.69,6.69,0,0,1,.5,2.8v59.9h8.32a7.17,7.17,0,0,1,5.05,2,6.67,6.67,0,0,1,2.08,5.1,6.82,6.82,0,0,1-2.08,5.1,7.17,7.17,0,0,1-5.05,2H209v20.3a7.14,7.14,0,0,1-7.14,7.1,6.63,6.63,0,0,1-5-2.1A6.91,6.91,0,0,1,194.82,170.1Zm0-34.5V98.4l-25.77,37.3,25.77-.1h0Z"
          transform="translate(-14.45 -62.47)"
        />
        <g id="kitty">
          <path
            class="cls-2"
            d="M88.68,167.1c-11.69-14,.1-38.9,9.51-52.8a1.3,1.3,0,0,0,1.19-.2c0.3-1.2,4.16-7.5,5.45-8.5,4.06-2.9,10.6-2.6,15.26-1.7a16.28,16.28,0,0,1,7.83,3.6,16.28,16.28,0,0,1,7.83-3.6c4.76-.9,11.2-1.2,15.26,1.7,1.29,0.9,5.15,7.2,5.45,8.5a1.61,1.61,0,0,0,1.19.2c9.41,13.8,20.42,39.6,7.53,53.8C153.29,181.4,96.11,180.1,88.68,167.1Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-3"
            d="M115,176.8l-0.1-1.1c-0.69-4-3.67-7.8-10.41-7.8,0.5-13.7,10.21-24.6,22.2-24.6s22.6,9.6,23,23.5c-1.68-1.3-11,4.2-10.31,9.3l0.2,0.7,4.16-.5a118.77,118.77,0,0,1-24.68.8Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-2"
            d="M135.26,72.1c1.19-1.7,9.81-10.8,11.69-9.5,2.58,1.8,4,12,4,14.9a4.18,4.18,0,0,0,.2,1.6,7.79,7.79,0,0,0,.2,1.5,9.43,9.43,0,0,1,.4,1.6l-0.89,1.6C145.07,82.1,132.88,82.1,135.26,72.1Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-4"
            d="M146.06,68.1a3.61,3.61,0,0,1,2.18-3.3h0.1l0.2,0.5a39,39,0,0,1,1.29,4.4l0.4,1.9-0.59.1A3.7,3.7,0,0,1,146.06,68.1Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-2"
            d="M113.85,72.1c-1.19-1.7-9.81-10.8-11.69-9.5-2.58,1.8-4,12-4,14.9a4.18,4.18,0,0,1-.2,1.6,7.79,7.79,0,0,1-.2,1.5,9.43,9.43,0,0,0-.4,1.6l0.89,1.6C104,82.1,116.23,82.1,113.85,72.1Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-4"
            d="M98.89,71.6l0.4-1.9a25.54,25.54,0,0,1,1.29-4.4l0.2-.5h0.1A3.51,3.51,0,0,1,103,68.1a3.57,3.57,0,0,1-3.57,3.6Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-3"
            d="M125.94,78.9a31,31,0,1,1-31,31A31,31,0,0,1,125.94,78.9Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-4"
            d="M97,128.6a1,1,0,0,1,.2-1.3l9.51-6.7a0.93,0.93,0,0,1,1.29.2,1,1,0,0,1-.2,1.3l-9.51,6.7A0.92,0.92,0,0,1,97,128.6Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-4"
            d="M93.14,124.1a0.87,0.87,0,0,1,.4-1.2l11.1-5.8a0.86,0.86,0,0,1,1.19.4,0.87,0.87,0,0,1-.4,1.2l-11.1,5.8A1,1,0,0,1,93.14,124.1Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-4"
            d="M152,127.9l-9.51-6.7a0.93,0.93,0,0,1,1.09-1.5l9.51,6.7A0.93,0.93,0,0,1,152,127.9Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-4"
            d="M155.87,123.6l-11.1-5.8a1,1,0,0,1-.4-1.2,1,1,0,0,1,1.19-.4l11.1,5.8a1,1,0,0,1,.4,1.2A0.91,0.91,0,0,1,155.87,123.6Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-5"
            d="M100.27,93.5a10.2,10.2,0,1,1-10.21,10.2A10.22,10.22,0,0,1,100.27,93.5Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-5"
            d="M150.12,93.5a10.2,10.2,0,1,1-10.21,10.2A10.22,10.22,0,0,1,150.12,93.5Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-4"
            d="M136.55,92.9a0.87,0.87,0,0,1-.69-1l0.89-5.2a0.91,0.91,0,0,1,1.09-.7,0.87,0.87,0,0,1,.69,1l-0.89,5.2A1,1,0,0,1,136.55,92.9Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-4"
            d="M140.11,93.6a0.87,0.87,0,0,1-.69-1l0.89-5.2a0.91,0.91,0,0,1,1.09-.7,0.87,0.87,0,0,1,.69,1l-0.89,5.2A1.12,1.12,0,0,1,140.11,93.6Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-4"
            d="M113.75,91.9l-1.29-5.1a0.92,0.92,0,0,1,.69-1.1,0.94,0.94,0,0,1,1.09.6l1.29,5.1a0.92,0.92,0,0,1-.69,1.1A0.94,0.94,0,0,1,113.75,91.9Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-4"
            d="M110.18,92.8l-1.29-5.1a0.92,0.92,0,0,1,.69-1.1,0.94,0.94,0,0,1,1.09.6L112,92.3a0.92,0.92,0,0,1-.69,1.1A0.79,0.79,0,0,1,110.18,92.8Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-1"
            d="M84,109.8A41.23,41.23,0,1,1,125.25,151,41.23,41.23,0,0,1,84,109.8Zm71.75,0a30.52,30.52,0,1,0-30.52,30.5A30.55,30.55,0,0,0,155.77,109.8Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-1"
            d="M149.43,173l-12-23.3a6.75,6.75,0,1,1,12-6.2l12,23.3A6.75,6.75,0,1,1,149.43,173Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-4"
            d="M119.5,110.5a1.25,1.25,0,0,1-.59-1.8,1.23,1.23,0,0,1,1.78-.6c3,1.4,7.33,1.6,9.32-.2a1.37,1.37,0,0,1,1.88.1,1.4,1.4,0,0,1-.1,1.9C128.62,112.7,123.07,112.2,119.5,110.5Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-4"
            d="M124.06,116.3v-5.7a1.39,1.39,0,0,1,2.77,0v5.7a1.34,1.34,0,0,1-1.39,1.3A1.23,1.23,0,0,1,124.06,116.3Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-4"
            d="M133.18,121.8a19.88,19.88,0,0,1-7.14-3.5l-0.5-.4-0.4.4a14.44,14.44,0,0,1-7.33,3.5,1.5,1.5,0,0,1-1.59-1.1,1.27,1.27,0,0,1,1.09-1.5,13.25,13.25,0,0,0,7.14-3.9,1.35,1.35,0,0,1,1.78-.1,17.76,17.76,0,0,0,7.53,4,1.35,1.35,0,0,1,1,1.6A1.19,1.19,0,0,1,133.18,121.8Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-2"
            d="M146.55,102.9a3.7,3.7,0,1,1-3.67,3.7A3.61,3.61,0,0,1,146.55,102.9Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-2"
            d="M104.83,102.9a3.7,3.7,0,1,1-3.67,3.7A3.67,3.67,0,0,1,104.83,102.9Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-2"
            d="M97.9,142c0.4-.4,8.52-5.3,9.22-5.2,6,1.2-.4,13-5.15,12.2-2-.3-5.25-4.6-5.35-6.3C96.71,142.6,97.7,142.2,97.9,142Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-2"
            d="M152.6,145.7c-0.5.5-2.58,4.9-3.07,5.6-1.49,2.1-5.05,2.3-5.75,4.6-1.78,6,12.29,4.6,15.16-1.1-0.3-1.6-5.65-9.2-6.34-9.1C152.7,145.6,152.7,145.7,152.6,145.7Z"
            transform="translate(-14.45 -62.47)"
          />
          <path
            class="cls-2"
            d="M71.83,173a17.64,17.64,0,0,1-11.5-7.6c-2.77-4.3-3.47-9.8-1.59-15.9a4.53,4.53,0,0,1,5.65-3,4.4,4.4,0,0,1,3,5.6c-2.38,7.7,2,11.2,6.24,12.1a10.58,10.58,0,0,0,7.23-1.1,9.07,9.07,0,0,0,4.26-6.4,4.58,4.58,0,0,1,5.25-3.6,4.42,4.42,0,0,1,3.57,5.2,18,18,0,0,1-8.62,12.5A18.9,18.9,0,0,1,71.83,173Z"
            transform="translate(-14.45 -62.47)"
          />
        </g>
      </svg>

      <div class="pl-3 mt-15">
        <div class="text-h4">
          Упс, а такой страницы нет :(
        </div>
        <v-btn
          color="primary"
          class="font-weight-bold mt-4"
          text
          @click="$router.back()"
        >
          Вернутся на предыдущей странице
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.error-font {
  font-size: 220px;
}

.cls-1 {
  fill: var(--v-primary-base);
}
.cls-2 {
  fill: #3c464c;
}
.cls-3 {
  fill: #4f5f66;
}
.cls-4 {
  fill: #93a5b2;
}
.cls-5 {
  fill: #fff;
}
</style>
